export const BackArrowBtnSvg = ({ handleClose }) => {
  return (
    <svg
      className="set_back"
      onClick={handleClose}
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{
        fill: "black",
        fillRule: "evenodd",
        clipRule: "evenodd",
      }}
      viewBox="0 0 4700 8900"
    >
      <path
        className="fil0"
        d="M4589.92 625.66c118.95,-150.59 105.84,-371.54 -35.63,-506.49l-0.01 -0.01c-150.06,-143.14 -389.92,-137.46 -533.06,12.59l-3866.91 4054.06c-78.5,82.32 -112.23,191.64 -101.94,297.2 6.63,83.28 40.65,164.85 102.45,229.68l3865.71 4055.19c143.09,150.1 382.96,155.85 533.06,12.77l0 -0.01c142.17,-135.52 154.81,-357.82 34.19,-508.48l-3393.15 -3559.47c-61.8,-64.83 -95.82,-146.4 -102.45,-229.68 -10.29,-105.56 23.44,-214.88 101.94,-297.2l3395.8 -3560.15z"
      ></path>
    </svg>
  );
};

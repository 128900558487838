export const SubmitBtnSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlSpace="preserve"
      style={{ fillRule: "evenodd", clipRule: "evenodd" }}
      viewBox="0 0 7000 5100"
    >
      <polygon
        className="fil0"
        points="58.71,2577.39 704.17,1931.94 2565.69,3793.47 6293.43,47.02 6938.9,692.46 2556.34,5075.02 "
      ></polygon>
    </svg>
  );
};

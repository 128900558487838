export const toggleZoom = (selector, enable) => {
  const element = document.querySelector(selector);

  if (enable) {
    // Увімкнути можливість масштабування
    element.style.touchAction = "auto";  
    element.style.userSelect = "auto";  
    element.style.overflow = "auto"; 

    // Видалити блокування масштабування
    element.removeEventListener("wheel", (e) => e.preventDefault());
    element.removeEventListener("touchmove", (e) => e.preventDefault());
    element.removeEventListener("gesturestart", (e) => e.preventDefault());
    element.removeEventListener("gesturechange", (e) => e.preventDefault());
    element.removeEventListener("dblclick", (e) => e.preventDefault());
  } else {
    // Вимкнути можливість масштабування
    element.style.touchAction = "none";
    element.style.userSelect = "none";
    element.style.overflow = "hidden"; 

    // Блокувати події для масштабування
    element.addEventListener("wheel", (e) => e.preventDefault(), { passive: false });
    element.addEventListener("touchmove", (e) => e.preventDefault(), { passive: false });
    element.addEventListener("gesturestart", (e) => e.preventDefault(), { passive: false });
    element.addEventListener("gesturechange", (e) => e.preventDefault(), { passive: false });
    element.addEventListener("dblclick", (e) => e.preventDefault(), { passive: false });
  }

  console.log(`Zoom has been ${enable ? "enabled" : "disabled"} for ${selector}`);
};

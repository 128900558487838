import resources from "./messages";

export const lang = Object.keys(resources);

export const langNames = (() => {
  const lang_data = {};
  lang.forEach((l) => (lang_data[l] = resources[l]["langName"]));

  return lang_data;
})();

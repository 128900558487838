import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./i18n";
import Swiper_block from "./components/Swiper/Swiper";
import Authorize from "./components/Authorize/Authorize";
import DN from "./components/DownloadNotification/DownloadNotification";
import Registration from "./components/Registration/Registration";
import CameraViewer from "./components/CameraViewer/CameraViewer";
import Main from "./components/Main/Main";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.css";
import { apiRequest } from "utils/api";
import { setUser } from "./features/auth/authSlice";
import { useTranslation } from "react-i18next";

function App() {
  const { showAuthorize, showSettings } = useSelector((state) => state.auth);

  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const { i18n } = useTranslation();

  const [cameraOn, setCameraOn] = useState(false);
  const [registrationOn, setRegistrationOn] = useState(false);

  useEffect(() => {
    apiRequest("check_auth.php", "GET")
      .then((response) => {
        if (response.isAuthenticated) {
          console.log("User is authenticated", response.user);
          // Встановіть користувача у стейті або виконайте інші дії
          dispatch(setUser(response.user));
        } else {
          console.log("User is not authenticated");
          // Направте користувача на сторінку авторизації або виконайте інші дії
        }
      })
      .catch((error) => {
        console.error("Error checking auth:", error);
      });

    apiRequest("edit_profile.php", "GET")
      .then((res) => {
        if (res.status === "success") {
          i18n.changeLanguage(res.lang);
        }
      })
      .catch((error) => console.error(error.message));
  }, [dispatch]);

  return (
    <div>
      <DN />
      {/* <Swiper_block /> */}
      {showAuthorize && <Authorize setRegistrationOn={setRegistrationOn} />}
      {registrationOn && <Registration setRegistrationOn={setRegistrationOn} />}
      {cameraOn && <CameraViewer setCameraOn={setCameraOn} />}
      <Main setCameraOn={setCameraOn} />
    </div>
  );
}

export default App;

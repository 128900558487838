import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useTranslation } from "react-i18next";

import {
  clearUser,
  toggleSettings,
  toggleAuthorize,
} from "../../features/auth/authSlice";
import "./styles.css";
import { apiRequest } from "utils/api";
import Lightbox from "./Lightbox";
import DeletePostLightbox from "./DeletePostLightbox";
import UnsubscribeLightbox from "./UnsubscribeLightbox";
import { showEditProfile } from "features/editProfileSlice/editProfileSlice";
import { fileToDataURI } from "utils/converter";
import ProfilePhotoEditor from "./ProfilePhotoEditor";

function Settings() {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [settings, setSettings] = useState(null); // Стан для налаштувань
  console.log("settings: ", settings);
  const [error, setError] = useState(null); // Стан для помилки
  const [loading, setLoading] = useState(true); // Індикатор завантаження
  const [posts, setPosts] = useState([]); // Стан для збереження постів користувача
  const [favorites, setFavorites] = useState([]); // Стан для збереження улюблених постів
  const [subscribedUsers, setSubscribedUsers] = useState([]); // Стан для збереження підписок
  const [activeTab, setActiveTab] = useState(0); // Стан для активної вкладки
  const { t } = useTranslation();

  const [isLightboxVisible, setIsLightboxVisible] = useState(false);
  const [selectedFavoriteId, setSelectedFavoriteId] = useState(null);

  const [isPostLightboxVisible, setIsPostLightboxVisible] = useState(false);
  const [selectedPostId, setSelectedPostId] = useState(null);

  const [isUnsubscribeBoxVisible, setIsUnsubscribeBoxVisible] = useState(false);
  const [selectedPublisherId, setSelectedPublisherId] = useState(null);

  const [refetch, setRefetch] = useState(false);
  const [profilePhoto, setProfilePhoto] = useState("");

  // Функція для завантаження постів
  const loadMyPosts = async () => {
    try {
      setLoading(true); // Починаємо завантаження
      const response = await apiRequest("load_my_posts.php", "GET");
      setPosts(response.posts); // Зберігаємо пости в стані
      setLoading(false); // Завершуємо завантаження
    } catch (error) {
      console.error("Error loading posts:", error);
      setLoading(false); // У разі помилки завершуємо завантаження
    }
  };

  // Функція для завантаження улюблених постів
  const loadMyFavorites = async () => {
    try {
      setLoading(true); // Починаємо завантаження
      const response = await apiRequest("load_my_favorites.php", "GET");
      setFavorites(response.favorites); // Зберігаємо улюблені пости в стані
      setLoading(false); // Завершуємо завантаження
    } catch (error) {
      console.error("Error loading favorites:", error);
      setLoading(false); // У разі помилки завершуємо завантаження
    }
  };

  // Функція для завантаження підписок
  const loadMySubscribedUsers = async () => {
    try {
      setLoading(true);
      const response = await apiRequest("/load_my_subscribed.php", "GET");
      setSubscribedUsers(response.subscribed);
      setLoading(false);
    } catch (error) {
      console.error("Error loading subscribed users:", error);
      setLoading(false);
    }
  };

  // Обробка зміни активної вкладки
  const handleTabClick = (tabIndex) => {
    console.log("tabIndex", tabIndex);
    setActiveTab(tabIndex); // Set the active tab

    if (tabIndex === 0) {
      loadMyPosts(); // Load posts if the first tab is clicked
    } else if (tabIndex === 1) {
      loadMyFavorites(); // Load favorites if the second tab is clicked
    } else if (tabIndex === 2) {
      loadMySubscribedUsers(); // Load subscribed users if the third tab is clicked
    }

    // Apply stop-color updates
    const tabHeader = document.querySelector(".tab_header");
    if (tabHeader) {
      const stopElements = tabHeader.querySelectorAll("div svg stop");
      stopElements.forEach((stop) => {
        stop.setAttribute("stop-color", "#e0e5f1");
      });

      const currentTabStops =
        tabHeader.children[tabIndex]?.querySelectorAll("svg stop");
      if (currentTabStops && currentTabStops.length >= 2) {
        currentTabStops[0].setAttribute("stop-color", "#e70489");
        currentTabStops[1].setAttribute("stop-color", "#fa5f6f");
      }
    }
  };

  // Функція для визначення стилю margin-left для runner
  const getRunnerStyle = () => {
    switch (activeTab) {
      case 0:
        return { marginLeft: "calc(16.6667% - 18.6667px)" };
      case 1:
        return { marginLeft: "calc(50% - 18.6667px)" };
      case 2:
        return { marginLeft: "calc(83.3333% - 18.6667px)" };
      default:
        return { marginLeft: "calc(16.6667% - 18.6667px)" };
    }
  };

  // Функція для відкриття лайтбоксу
  const handleDeleteFavorite = (favId) => {
    setSelectedFavoriteId(favId);
    setIsLightboxVisible(true);
  };

  // Функція для закриття лайтбоксу
  const handleCloseLightbox = () => {
    setIsLightboxVisible(false);
    setSelectedFavoriteId(null);
  };

  // Функція для підтвердження видалення
  const handleConfirmDelete = async () => {
    if (!selectedFavoriteId) return;

    try {
      const response = await apiRequest("delete_my_favorites.php", "POST", {
        postid: selectedFavoriteId, // Передаємо ID поста для видалення
      });

      if (response.status === "success") {
        // Видаляємо пост з улюблених на фронтенді
        setFavorites(favorites.filter((fav) => fav.id !== selectedFavoriteId));
        handleCloseLightbox(); // Закриваємо лайтбокс після успішного видалення
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.error("Error deleting favorite:", error);
    }
  };

  // Функція для відкриття лайтбоксу видалення поста
  const handleDeletePost = (postId) => {
    setSelectedPostId(postId);
    setIsPostLightboxVisible(true);
  };

  // Функція для закриття лайтбоксу
  const handleClosePostLightbox = () => {
    setIsPostLightboxVisible(false);
    setSelectedPostId(null);
  };

  // Функція для підтвердження видалення поста
  const handleConfirmDeletePost = async () => {
    if (!selectedPostId) return;

    try {
      const response = await apiRequest("delete_my_post.php", "POST", {
        postid: selectedPostId, // Передаємо ID поста для видалення
      });

      if (response.status === "success") {
        // Видаляємо пост з масиву постів на фронтенді
        setPosts(posts.filter((post) => post.id !== selectedPostId));
        handleClosePostLightbox(); // Закриваємо лайтбокс після успішного видалення
      } else {
        console.error(response.message);
      }
    } catch (error) {
      console.error("Error deleting post:", error);
    }
  };

  // Відкриття лайтбоксу для підтвердження відписки
  const handleUnsubscribeClick = (publisherId) => {
    setSelectedPublisherId(publisherId);
    setIsUnsubscribeBoxVisible(true);
  };

  // Підтвердження відписки
  const handleConfirmUnsubscribe = async () => {
    try {
      await apiRequest("/subscribe_user.php", "POST", {
        publisher_id: selectedPublisherId,
        subscribe: 0,
      });
      // Оновлення списку підписок
      setSubscribedUsers(
        subscribedUsers.filter((user) => user.id !== selectedPublisherId)
      );
      setIsUnsubscribeBoxVisible(false);
    } catch (error) {
      console.error("Error unsubscribing:", error);
    }
  };

  const handleEditProfileClick = () => {
    dispatch(showEditProfile());
  };

  const handleCloseEditProfile = () => {
    setShowEditProfile(false);
  };

  useEffect(() => {
    console.log("starting settings component");
    console.log(user);
    if (!user) return; // Перевірка, чи є користувач

    // Запит до API для отримання налаштувань
    axios
      .post(
        `https://letmestyle.com/api/v1/load_settings.php`,
        { user_id: user.id },
        {
          mode: "no-cors",
          withCredentials: true, // Дозволяє передавати кукі між різними доменами
          credentials: "include",
          headers: {
            "Content-Type": "multipart/form-data",
            "X-Requested-With": "XMLHttpRequest", // Додаємо спеціальний заголовок для запитів
          },
        }
      )
      .then((response) => {
        if (response.data.error) {
          if (response.data.error === "session expired") {
            dispatch(clearUser()); // Очищення даних користувача
            dispatch(toggleSettings()); // Закриття сторінки налаштувань
            dispatch(toggleAuthorize()); // Відкриття сторінки авторизації
          } else {
            setError(response.data.error);
          }
        } else {
          console.log(response.data);
          setSettings(response.data); // Збереження даних у стані
        }
      })
      .catch((err) => {
        setError("Помилка завантаження налаштувань.");
        console.error(err);
      });
    loadMyPosts(); // Завантажуємо пости при монтуванні компонента
  }, [user, dispatch, refetch]);

  const handleLogout = () => {
    dispatch(clearUser());
    dispatch(toggleAuthorize());
    dispatch(toggleSettings());
  };

  const handleClose = () => {
    dispatch(toggleSettings());
  };

  // Перевірка на помилку або стан завантаження
  if (error) {
    return <div>{error}</div>;
  }

  if (!settings) {
    return <div>{t("system.loading")}...</div>;
  }

  return (
    <div
      id="settings"
      style={{ zIndex: 100, visibility: "visible", left: "0px", top: "0px" }}
    >
      {profilePhoto && (
        <ProfilePhotoEditor
          photo={profilePhoto}
          setPhoto={setProfilePhoto}
          refetch={refetch}
          setRefetch={setRefetch}
        />
      )}
      <div className="top_nav">
        <div className="button_left" onClick={handleClose}>
          <svg
            className="set_back"
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            style={{ fill: "black", fillRule: "evenodd", clipRule: "evenodd" }}
            viewBox="0 0 4700 8900"
          >
            <path
              className="fil0"
              d="M4589.92 625.66c118.95,-150.59 105.84,-371.54 -35.63,-506.49l-0.01 -0.01c-150.06,-143.14 -389.92,-137.46 -533.06,12.59l-3866.91 4054.06c-78.5,82.32 -112.23,191.64 -101.94,297.2 6.63,83.28 40.65,164.85 102.45,229.68l3865.71 4055.19c143.09,150.1 382.96,155.85 533.06,12.77l0 -0.01c142.17,-135.52 154.81,-357.82 34.19,-508.48l-3393.15 -3559.47c-61.8,-64.83 -95.82,-146.4 -102.45,-229.68 -10.29,-105.56 23.44,-214.88 101.94,-297.2l3395.8 -3560.15z"
            ></path>
          </svg>
        </div>
        <div style={{ marginLeft: "28pt" }}>{t("profile")}</div>
        <div className="button_left">
          <svg
            id="edit_profile_button"
            onClick={handleEditProfileClick}
            xmlns="http://www.w3.org/2000/svg"
            style={{ fillRule: "evenodd", clipRule: "evenodd" }}
            viewBox="0 0 900 900"
          >
            <path
              className="fil0"
              d="M771.51 103.73c-38.45,-38.5 -58.82,-56.02 -128.02,13.27l-25.6 25.32 141.14 141.33 32.83 -33.62c62.01,-62.1 37.13,-88.85 5.97,-120.05 -22.24,-22.24 -4.07,-4.01 -26.32,-26.25zm-206.15 90.54l-400.35 395.95 -95.05 239.72 245.15 -91.55 392.31 -401.87 -142.06 -142.25zm-560.37 609.73l95.05 -239.73 0.19 0.08c3.43,-8.64 8.65,-16.73 15.66,-23.7l478.48 -473.22c49.97,-49.98 88.18,-68.49 126.79,-67.38 45.34,1.29 69.33,23.55 99.85,54.12l-0.19 0.19c10.69,10.69 11.73,11.72 12.77,12.74l0.19 0.19c1.01,0.99 2.01,1.98 13.36,13.32l0.19 -0.19c24.35,24.38 46.3,47.4 51.68,89.09 5.09,39.48 -8.72,81.09 -57.65,130.08l-0.19 -0.19 -474.8 486.37c-7.19,7.81 -16.27,14.1 -26.9,18.07l-240.25 89.72c-16.81,7.74 -36.63,8.84 -55.17,1.44 -35.88,-14.33 -53.37,-55.07 -39.06,-91z"
            ></path>
          </svg>
          <svg
            id="logout_profile_button"
            xmlns="http://www.w3.org/2000/svg"
            style={{
              shapeRendering: "geometricPrecision",
              textRendering: "geometricPrecision",
              imageRendering: "optimizeQuality",
              fillRule: "evenodd",
              clipRule: "evenodd",
            }}
            viewBox="0 0 900 900"
            onClick={handleLogout}
          >
            <path
              className="fil0"
              d="M498.89 101.89c0,-15.47 -7.03,-30 -19.22,-39.38 -12.19,-9.38 -27.97,-12.81 -42.97,-9.06l-281.09 70.31c-33.28,8.29 -56.72,38.29 -56.72,72.66 0,218.49 0,436.98 0,655.47l350 0 50 0 0 -50 0 -700zm-100 350c0,27.66 -16.72,50 -37.5,50 -20.78,0 -37.5,-22.34 -37.5,-50 0,-27.66 16.72,-50 37.5,-50 20.78,0 37.5,22.34 37.5,50zm150 -226.46l140.28 0c20.87,0 36.17,12.55 36.17,38.37 0,192.84 0,397.52 0,588.09l73.55 0c0,-142.59 0,-383.25 0,-600 0,-55.16 -44.84,-100 -100,-100l-150 0 0 73.54z"
            ></path>
          </svg>
        </div>
      </div>
      <div className="block profile">
        <div className="avatar">
          <label htmlFor="fileInput" className="fileInput"></label>
          <input
            type="file"
            id="fileInput"
            accept="capture=camera,image/*"
            onChange={(event) => {
              fileToDataURI(event.target.files[0]).then((data) =>
                setProfilePhoto(data)
              );
              event.target.value = null;
            }}
          />
          <img
            src={
              process.env.REACT_APP_IMG_DOMAIN + settings.profile.photo ||
              `${process.env.REACT_APP_IMG_DOMAIN}/img/icons/default_avatar.png`
            }
            alt="User Avatar"
          />
        </div>
        <div className="nickname">{settings.profile.nickname || "User"}</div>
        <div className="buttons">
          <div className="profile-box">
            <span>{settings.profile.posts_count}</span>
            <span>{t("posts")}</span>
          </div>
          <div className="profile-box">
            <span id="subscribers_count">
              {settings.profile.subscribers_count}
            </span>
            <span>{t("subscribers")}</span>
          </div>
        </div>
      </div>
      <div className="grid_container">
        <div className="slide">
          <div></div>
        </div>
        <div className="profile_tabs">
          <div className="tab_header">
            <div
              className={activeTab === 0 ? "active" : ""}
              onClick={() => handleTabClick(0)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000">
                <defs>
                  <linearGradient
                    id="MyGradient1"
                    gradientTransform="rotate(30)"
                  >
                    <stop offset="0%" stopColor="#e70489"></stop>
                    <stop offset="100%" stopColor="#fa5f6f"></stop>
                  </linearGradient>
                </defs>
                <path
                  className="gradientable1"
                  d="M132.99 0l598.47 0c73.14,0 132.99,59.84 132.99,132.98l0 597.72c0,73.14 -59.85,132.98 -132.99,132.98l-598.47 0c-73.14,0 -132.99,-59.84 -132.99,-132.98l0 -597.72c0,-73.14 59.85,-132.98 132.99,-132.98zm1135.56 0l598.48 0c73.14,0 132.98,59.84 132.98,132.98l0 597.72c0,73.14 -59.84,132.98 -132.98,132.98l-598.48 0c-73.14,0 -132.98,-59.84 -132.98,-132.98l0 -597.72c0,-73.14 59.84,-132.98 132.98,-132.98zm-1135.56 1136.32l598.47 0c73.14,0 132.99,59.84 132.99,132.98l0 597.72c0,73.14 -59.85,132.98 -132.99,132.98l-598.47 0c-73.14,0 -132.99,-59.84 -132.99,-132.98l0 -597.72c0,-73.14 59.85,-132.98 132.99,-132.98zm1135.56 0l598.48 0c73.14,0 132.98,59.84 132.98,132.98l0 597.72c0,73.14 -59.84,132.98 -132.98,132.98l-598.48 0c-73.14,0 -132.98,-59.84 -132.98,-132.98l0 -597.72c0,-73.14 59.84,-132.98 132.98,-132.98z"
                ></path>
              </svg>
            </div>
            <div
              className={activeTab === 1 ? "active" : ""}
              onClick={() => handleTabClick(1)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2601 2501">
                <defs>
                  <linearGradient
                    id="MyGradient2"
                    gradientTransform="rotate(30)"
                  >
                    <stop offset="0%" stopColor="#e0e5f1"></stop>
                    <stop offset="100%" stopColor="#e0e5f1"></stop>
                  </linearGradient>
                </defs>
                <path
                  className="gradientable2"
                  d="M1302.19 3.25c98.33,-0.07 130.5,71.25 195.75,210.87l253.03 541.42c196.72,24.86 393.44,49.72 590.16,74.58 152.33,19.25 229.37,37.58 253.45,113.53 24.08,75.94 -21.6,156.51 -134.18,262.66l-432.07 407.38c36.87,193.63 73.73,387.26 110.6,580.9 29.27,153.72 29.3,238.09 -30.67,281.44 -59.97,43.36 -155.54,28.07 -292.55,-47.76l-513.52 -284.22c-171.08,94.69 -342.15,189.37 -513.23,284.06 -137.1,75.88 -235.41,89.96 -288.88,51.46 -53.47,-38.5 -63.97,-130.91 -34.73,-284.46l110.7 -581.42c-143.66,-135.44 -287.3,-270.89 -430.95,-406.33 -112.95,-106.5 -162.11,-173.82 -138.46,-262.54 23.64,-88.72 105.74,-95.64 258.81,-114.98l587.96 -74.3c84.3,-180.38 168.6,-360.76 252.9,-541.14 65.29,-139.72 97.55,-211.08 195.88,-211.15z"
                ></path>
              </svg>
            </div>
            <div
              className={activeTab === 2 ? "active" : ""}
              onClick={() => handleTabClick(2)}
            >
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000">
                <defs>
                  <linearGradient
                    id="MyGradient3"
                    gradientTransform="rotate(30)"
                  >
                    <stop offset="0%" stopColor="#e0e5f1"></stop>
                    <stop offset="100%" stopColor="#e0e5f1"></stop>
                  </linearGradient>
                </defs>
                <path
                  className="gradientable3"
                  d="M755.33 1192.26c-127.52,11.22 -299.27,75.21 -390.31,165.05 -16.86,16.64 -31.23,23.19 -44.6,38.81 -96.85,113.17 -174.97,235.36 -193.59,443.4 -10.72,119.72 39.31,160.81 154.26,160.45l1441.25 0.03c183.85,0.11 166.58,-142.43 125.13,-303.52 -22.29,-86.59 -62.23,-154.48 -104.22,-217.66 -69.74,-104.95 -209.54,-216.22 -373.68,-263.91 -132.45,-38.48 -465.99,-35.69 -614.24,-22.65zm208.44 -1191.08c-358.47,24.06 -571.82,408.35 -408.01,729.36 82.53,161.73 265.5,280.81 478.96,266.9 259.28,-16.9 482.22,-244.94 462.11,-534.23 -18.25,-262.44 -246.04,-481.29 -533.06,-462.03z"
                ></path>
              </svg>
            </div>
            <div className="runner">
              <div style={getRunnerStyle()}></div>
            </div>
          </div>
          <div className="grid" id="my_media">
            {/* <div className="no_comments">You have not yet posts</div> */}
            {loading ? (
              // Показуємо індикатор завантаження, якщо пости ще не завантажилися
              <div className="lds">
                <div className="lds-dual-ring"></div>
              </div>
            ) : (
              <>
                {activeTab === 0 &&
                  posts.length > 0 &&
                  // Відображаємо пости, якщо активна перша вкладка
                  posts.map((post) => (
                    <div className="thumb" data-postid={post.id} key={post.id}>
                      <img
                        className="img"
                        src={post.pic}
                        alt={`Post ${post.id}`}
                      />
                      <div
                        className="det_trash trash_post thumb-button"
                        data-postid={post.id}
                        onClick={() => handleDeletePost(post.id)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlSpace="preserve"
                          style={{
                            fillRule: "evenodd",
                            clipRule: "evenodd",
                          }}
                          viewBox="0 0 6500 7401"
                        >
                          <path d="M4306.1 2323.62l0.01 0c115.93,4.74 206.9,103.46 202.16,219.39l-143.22 3504.25c-4.74,115.93 -103.46,206.89 -219.4,202.16l-0.01 0c-115.93,-4.75 -206.89,-103.47 -202.15,-219.4l143.22 -3504.24c4.74,-115.93 103.45,-206.9 219.39,-202.16zm-1924.46 4414.06l1695.18 0c304.29,0 623.13,31.3 740.04,-85.63 116.92,-116.93 129.28,-437.26 149.08,-744.91l276.42 -4057.72 -4025.94 0 276.41 4057.66c29.7,388.28 0.93,582.58 137.28,706.75 136.35,124.17 334.04,123.85 751.53,123.85zm46.77 -6737.43l1637.82 0c236.23,0 316.23,89.36 387.47,193.73l677.64 992.9 1030.7 0 0.78 -0.01c182.94,-2.34 331.29,148.32 331.29,331.29 0,182.96 -148.33,331.28 -331.29,331.28l-1.55 -0.02 -256.33 0 -329.19 4857.72 -0.35 -0.03c-30.61,387.9 -355.06,693.14 -750.82,693.14l-3190.71 0c-395.74,0 -720.19,-305.22 -750.82,-693.11l-0.02 0 -0.07 -0.99 -0.45 -6.62 -328.67 -4850.11 -220.46 0 -1.55 0.02c-182.96,0 -331.29,-148.32 -331.29,-331.28 0,-182.97 148.33,-331.29 331.29,-331.29l0.78 0.01 1021.87 0 663.68 -992.73c80.06,-136.08 200.26,-193.9 410.25,-193.9zm1908.01 1186.63l-351.29 -524.09 -1481.35 0 -351.29 524.09 2183.93 0zm-2147.88 1136.74l-0.01 0c-115.93,4.74 -206.9,103.46 -202.16,219.39l143.22 3504.25c4.74,115.93 103.46,206.89 219.4,202.16l0.01 0c115.93,-4.75 206.89,-103.47 202.15,-219.4l-143.22 -3504.24c-4.74,-115.93 -103.45,-206.9 -219.39,-202.16zm1058.78 -1.64l0.01 0c116.03,0 210.95,94.92 210.95,210.95l0 3507.17c0,116.03 -94.92,210.95 -210.95,210.95l-0.01 0c-116.03,0 -210.95,-94.92 -210.95,-210.95l0 -3507.17c0,-116.03 94.92,-210.95 210.95,-210.95z"></path>
                        </svg>
                      </div>
                      <div className="details">
                        <div className="det thumb-button">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlSpace="preserve"
                            style={{
                              fillRule: "evenodd",
                              clipRule: "evenodd",
                            }}
                            viewBox="0 0 4300 4400"
                          >
                            <path d="M1134.52 2273.76c0,-229.3 282.23,-471.84 423.34,-707.76 134.79,-191.66 322.78,-483.78 328.13,-815.49 5.35,-331.71 -139.6,-718.81 157.97,-708.89 584,19.47 728.75,1029.48 473.01,1514.46 241.52,0 1072.6,0.02 1216.93,0.02 563.76,0 604.06,573.82 287.51,726.52 386.41,124.16 365.4,676.58 -64.92,733.18 204.24,142.4 213.37,582.48 -280.23,638.87 192.35,204.47 159.8,643.16 -603.66,643.16l-1435.37 0c-256.86,0 -502.71,-133.95 -502.71,-416.72 0,-535.78 0,-1071.57 0,-1607.35zm-994.48 -211.66l566.82 0c71.5,0 130,58.5 130,130l0 2061.72c0,71.5 -58.5,130 -130,130l-566.82 0c-71.5,0 -130,-58.5 -130,-130l0 -2061.72c0,-71.5 58.5,-130 130,-130z"></path>
                          </svg>
                          <span>{post.like_count}</span>
                        </div>
                        <div className="det thumb-button">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlSpace="preserve"
                            style={{
                              fillRule: "evenodd",
                              clipRule: "evenodd",
                            }}
                            viewBox="0 0 4300 4400"
                          >
                            <path
                              className="fil0"
                              d="M3171.6 2151.48c0,229.3 -282.23,471.84 -423.34,707.76 -134.79,191.66 -322.78,483.78 -328.13,815.49 -5.35,331.71 139.6,718.81 -157.97,708.89 -584,-19.47 -728.75,-1029.48 -473.01,-1514.46 -241.52,0 -1072.6,-0.02 -1216.93,-0.02 -563.76,0 -604.06,-573.82 -287.51,-726.52 -386.41,-124.16 -365.4,-676.58 64.92,-733.18 -204.24,-142.4 -213.37,-582.48 280.23,-638.87 -192.35,-204.47 -159.8,-643.16 603.66,-643.16l1435.37 0c256.86,0 502.71,133.95 502.71,416.72 0,535.78 0,1071.57 0,1607.35zm994.48 211.66l-566.82 0c-71.5,0 -130,-58.5 -130,-130l0 -2061.72c0,-71.5 58.5,-130 130,-130l566.82 0c71.5,0 130,58.5 130,130l0 2061.72c0,71.5 -58.5,130 -130,130z"
                            ></path>
                          </svg>
                          <span>{post.dislike_count}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                {activeTab === 1 &&
                  favorites.length > 0 &&
                  favorites.map((fav) => (
                    <div className="thumb" data-postid={fav.id} key={fav.id}>
                      <img
                        className="img"
                        src={fav.pic}
                        alt={`Favorite ${fav.id}`}
                      />
                      <div className="fav_author">
                        <div className="avatar">
                          <img src={fav.avatar} alt="Favorite Author Avatar" />
                        </div>
                        <span>{fav.nickname}</span>
                      </div>
                      <div
                        className="det_trash trash_fav thumb-button"
                        data-postid={fav.id}
                        onClick={() => handleDeleteFavorite(fav.id)}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlSpace="preserve"
                          style={{
                            fillRule: "evenodd",
                            clipRule: "evenodd",
                          }}
                          viewBox="0 0 6500 7401"
                        >
                          <path
                            className="fil0"
                            d="M4306.1 2323.62l0.01 0c115.93,4.74 206.9,103.46 202.16,219.39l-143.22 3504.25c-4.74,115.93 -103.46,206.89 -219.4,202.16l-0.01 0c-115.93,-4.75 -206.89,-103.47 -202.15,-219.4l143.22 -3504.24c4.74,-115.93 103.45,-206.9 219.39,-202.16zm-1924.46 4414.06l1695.18 0c304.29,0 623.13,31.3 740.04,-85.63 116.92,-116.93 129.28,-437.26 149.08,-744.91l276.42 -4057.72 -4025.94 0 276.41 4057.66c29.7,388.28 0.93,582.58 137.28,706.75 136.35,124.17 334.04,123.85 751.53,123.85zm46.77 -6737.43l1637.82 0c236.23,0 316.23,89.36 387.47,193.73l677.64 992.9 1030.7 0 0.78 -0.01c182.94,-2.34 331.29,148.32 331.29,331.29 0,182.96 -148.33,331.28 -331.29,331.28l-1.55 -0.02 -256.33 0 -329.19 4857.72 -0.35 -0.03c-30.61,387.9 -355.06,693.14 -750.82,693.14l-3190.71 0c-395.74,0 -720.19,-305.22 -750.82,-693.11l-0.02 0 -0.07 -0.99 -0.45 -6.62 -328.67 -4850.11 -220.46 0 -1.55 0.02c-182.96,0 -331.29,-148.32 -331.29,-331.28 0,-182.97 148.33,-331.29 331.29,-331.29l0.78 0.01 1021.87 0 663.68 -992.73c80.06,-136.08 200.26,-193.9 410.25,-193.9zm1908.01 1186.63l-351.29 -524.09 -1481.35 0 -351.29 524.09 2183.93 0zm-2147.88 1136.74l-0.01 0c-115.93,4.74 -206.9,103.46 -202.16,219.39l143.22 3504.25c4.74,115.93 103.46,206.89 219.4,202.16l0.01 0c115.93,-4.75 206.89,-103.47 202.15,-219.4l-143.22 -3504.24c-4.74,-115.93 -103.45,-206.9 -219.39,-202.16zm1058.78 -1.64l0.01 0c116.03,0 210.95,94.92 210.95,210.95l0 3507.17c0,116.03 -94.92,210.95 -210.95,210.95l-0.01 0c-116.03,0 -210.95,-94.92 -210.95,-210.95l0 -3507.17c0,-116.03 94.92,-210.95 210.95,-210.95z"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  ))}
                {activeTab === 2 &&
                  (subscribedUsers.length > 0 ? (
                    subscribedUsers.map((user) => (
                      <div className="sub_user" key={user.id}>
                        <div className="avatar">
                          <img
                            src={
                              user.photo ||
                              `${process.env.REACT_APP_IMG_DOMAIN}/img/icons/default_avatar.png`
                            }
                          />
                        </div>
                        <div className="misc">
                          <div className="labels">
                            <span>{user.nickname}</span>
                            <span>{t("system.no_new_posts")}</span>
                          </div>
                          <div
                            className="unsubscribe"
                            data-userid={user.id}
                            onClick={() => handleUnsubscribeClick(user.id)}
                          >
                            {t("system.unsubscribe")}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no_comments">
                      {t("system.have_not_subs")}
                    </div>
                  ))}
              </>
            )}
          </div>
        </div>
      </div>
      <Lightbox
        isVisible={isLightboxVisible}
        onClose={handleCloseLightbox}
        onConfirm={handleConfirmDelete}
        postId={selectedFavoriteId}
      />
      <DeletePostLightbox
        isVisible={isPostLightboxVisible}
        onClose={handleClosePostLightbox}
        onConfirm={handleConfirmDeletePost}
        postId={selectedPostId}
      />
      <UnsubscribeLightbox
        isVisible={isUnsubscribeBoxVisible}
        onClose={() => setIsUnsubscribeBoxVisible(false)}
        onConfirm={handleConfirmUnsubscribe}
        publisherId={selectedPublisherId}
      />
    </div>
  );
}

export default Settings;

import { AudioVisualizer } from "react-audio-visualize";

import { useEffect, useState } from "react";
import { apiRequest } from "utils/api";
import { useTranslation } from "react-i18next";

import "./Comment.css";

export default function Comment({ comment, audio, onDelete }) {
  const [audioPlayer, setAudioPlayer] = useState(
    new Audio(URL.createObjectURL(audio))
  );
  const [currentTime, setCurrentTime] = useState(0);
  const [isLiked, setIsLiked] = useState(comment.liked);
  const { t } = useTranslation();

  // Retrieve userId from JWT or localStorage
  const userId = JSON.parse(localStorage.getItem("user"))?.id;

  useEffect(() => {
    audioPlayer.addEventListener("timeupdate", (event) =>
      setCurrentTime(event.target.currentTime)
    );
    audioPlayer.addEventListener("loadedmetadata", () => {
      if (audioPlayer.duration === Infinity) {
        audioPlayer.currentTime = Number.MAX_SAFE_INTEGER;
        audioPlayer.ontimeupdate = () => {
          audioPlayer.ontimeupdate = null;
          audioPlayer.currentTime = 0;
        };
      }
    });
    return () => {
      audioPlayer.removeEventListener("timeupdate", (event) =>
        setCurrentTime(event.target.currentTime)
      );
      audioPlayer.removeEventListener("loadedmetadata", () => {
        if (audioPlayer.duration === Infinity) {
          audioPlayer.currentTime = Number.MAX_SAFE_INTEGER;
          audioPlayer.ontimeupdate = () => {
            audioPlayer.ontimeupdate = null;
            audioPlayer.currentTime = 0;
          };
        }
      });
    };
  }, []);

  // Function to handle the removal of the comment
  const handleRemoveComment = (commentId) => {
    apiRequest("remove_comment.php", "POST", { comment_id: commentId })
      .then((response) => {
        if (response.status === "success") {
          onDelete(commentId); // Call the onDelete function to update state
        } else {
          alert("Failed to delete comment");
        }
      })
      .catch((error) => {
        console.error("Error deleting comment:", error);
      });
  };

  return (
    <div className="comment">
      <img src={`https://letmestyle.com${comment.avatar}`} alt="avatar" />
      <div className="top">
        <h4>{comment.nickname}</h4>
        <h5>{comment.time}</h5>
      </div>
      <div className="sound">
        <button
          id="round-gradient"
          onClick={() => {
            audioPlayer.paused ? audioPlayer.play() : audioPlayer.pause();
          }}
        >
          <img
            src={`/svg/audio-comments/${
              audioPlayer.paused ? "play" : "pause"
            }.svg`}
            alt="button"
          />
        </button>
        <h4>{`${
          Number.isFinite(audioPlayer.duration)
            ? Math.floor(audioPlayer.duration)
            : 0
        }s`}</h4>
        <AudioVisualizer
          blob={audio}
          width={400}
          height={75}
          barWidth={3}
          gap={2}
          barColor={"#cddae8"}
          barPlayedColor={"#ff2b72"}
          currentTime={currentTime}
        />
      </div>
      <div className="bottom">
        <div className="comm-ui">
          <h5>Reply</h5>
          {Number(userId) === Number(comment.user_id) && (
            <h5 onClick={() => handleRemoveComment(comment.id)}>
              {t("system.remove")}
            </h5>
          )}
        </div>
        <div className="likes">
          <img
            src="/svg/audio-comments/like.svg"
            alt="like"
            onClick={(event) => {
              setIsLiked(!isLiked);
              event.target.style.animation = "liked ease-in-out 300ms";
              setTimeout(() => (event.target.style.animation = "none"), 300);
            }}
            style={{
              filter: isLiked
                ? "invert(38%) sepia(99%) saturate(4077%) hue-rotate(321deg) brightness(98%) contrast(106%)"
                : "",
            }}
          />
          <h5>{isLiked ? comment.like_count + 1 : comment.like_count}</h5>
        </div>
      </div>
    </div>
  );
}

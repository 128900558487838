import React, { useState } from "react";
import "./ReportLightbox.css";
import { useTranslation } from "react-i18next";

function ReportLightbox({ postId, onClose, onSubmit }) {
  const [selectedReason, setSelectedReason] = useState("");
  const [customReason, setCustomReason] = useState(""); // State for custom reason
  const { t } = useTranslation();

  // Handle reason selection
  const handleReasonChange = (event) => {
    setSelectedReason(event.target.value);
    if (event.target.value !== "r6") {
      setCustomReason(""); // Clear the custom reason when 'Інше' is not selected
    }
  };

  // Handle form submission
  const handleSubmit = () => {
    console.log("handleSubmit clicked");
    const reason = selectedReason === "r6" ? customReason : selectedReason;
    if (reason) {
      onSubmit({ postId, reason }); // Call the onSubmit callback with the selected reason
    } else {
      alert("Please select a reason for your report.");
    }
  };

  return (
    <div className="lightbox report-box">
      <div className="box">
        <div className="header">
          <span>{t("reports.complaint")}</span>
          <div className="close_report_box" onClick={onClose}>
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 501 501">
              <path
                className="fil0"
                d="M37.29 6.53l212.97 212.96 212.97 -212.98c8.44,-8.43 22.24,-8.43 30.67,0l0.02 0.03c8.45,8.43 8.45,22.24 0,30.67l-212.96 212.97 212.96 212.97c8.44,8.44 8.44,22.24 0,30.67l-0.02 0.02c-8.43,8.45 -22.24,8.45 -30.67,0l-212.97 -212.96 -212.96 212.95c-8.44,8.45 -22.24,8.45 -30.68,0l-0.01 -0.01c-8.45,-8.44 -8.45,-22.24 0,-30.67l212.95 -212.97 -212.95 -212.96c-8.45,-8.44 -8.45,-22.24 0,-30.68l0.01 -0.01c8.44,-8.45 22.24,-8.45 30.67,0z"
              />
            </svg>
          </div>
        </div>
        <div className="content">
          <input type="hidden" id="report_post_id" />
          <input
            type="radio"
            name="reason"
            id="reason1"
            value="r1"
            checked={selectedReason === "r1"}
            onChange={handleReasonChange}
          />
          <label htmlFor="reason1">{t("reports.spam").toUpperCase()}</label>
          <input
            type="radio"
            name="reason"
            id="reason2"
            value="r2"
            checked={selectedReason === "r2"}
            onChange={handleReasonChange}
          />
          <label htmlFor="reason2">{t("reports.nude")}</label>
          <input
            type="radio"
            name="reason"
            id="reason3"
            value="r3"
            checked={selectedReason === "r3"}
            onChange={handleReasonChange}
          />
          <label htmlFor="reason3">{t("reports.cruelty")}</label>
          <input
            type="radio"
            name="reason"
            id="reason6"
            value="r6"
            checked={selectedReason === "r6"}
            onChange={handleReasonChange}
          />
          <label htmlFor="reason6">{t("reports.other")}</label>

          {/* Textarea will be displayed only when "Інше" is selected */}
          {selectedReason === "r6" && (
            <textarea
              placeholder={t("reports.complaint_reason")}
              id="report_reason_text"
              value={customReason}
              onChange={(e) => setCustomReason(e.target.value)}
            />
          )}
          <div>
            <div className="button submit_report" onClick={handleSubmit}>
              {t("system.send")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ReportLightbox;

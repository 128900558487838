import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { hideEditProfile } from "../../features/editProfileSlice/editProfileSlice";
import { apiRequest } from "utils/api";
import "./styles.css";
import LightboxDeleteAccount from "./LightboxDeleteAccount";
import { lang, langNames } from "i18n/settings";
import { useTranslation } from "react-i18next";

const EditProfile = () => {
  const dispatch = useDispatch();
  const isVisible = useSelector((state) => state.editProfile.isVisible);
  const { t, i18n } = useTranslation();

  const [formData, setFormData] = useState({
    nickname: "",
    email: "",
    password: "",
    lang: "eng",
    gender: "na",
    age: ["", "", ""],
  });

  const [labels, setLabels] = useState({
    nickname: "Псевдонім",
    psw: "Пароль",
    interface_lang: "Мова інтерфейсу",
    gender: "Ваша стать",
    age: "Ваша дата народження",
    delete_account: "Видалити акаунт",
  });

  const [isDeleteVisible, setDeleteVisible] = useState(false);

  useEffect(() => {
    i18n.changeLanguage(formData.lang);
  }, [formData]);

  useEffect(() => {
    if (isVisible) {
      apiRequest("edit_profile.php", "GET")
        .then((response) => {
          if (response.status === "success") {
            const ageValue = response.age
              ? response.age.split("-")
              : ["1900", "01", "01"];

            setFormData({
              nickname: response.nickname || "",
              email: response.email || "",
              password: "", // пароль не отримуємо з API
              lang: response.lang || "eng",
              gender: response.gender || "na",
              age: ageValue,
            });
            setLabels(response.labels || labels);
          } else {
            console.error(response.message);
          }
        })
        .catch((error) => {
          console.error("Error fetching profile data:", error);
        });
    }
  }, [isVisible]);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handleAgeChange = (e, index) => {
    const { value } = e.target;
    setFormData((prevState) => {
      const newAge = [...prevState.age];
      newAge[index] = value;
      return { ...prevState, age: newAge };
    });
  };

  const handleSubmit = () => {
    const combinedAge = `${formData.age[0]}-${("0" + formData.age[1]).slice(-2)}-${("0" + formData.age[2]).slice(-2)}`;
    const updatedFormData = {
      ...formData,
      age: combinedAge,
    };

    apiRequest("edit_profile.php", "POST", updatedFormData)
      .then((response) => {
        if (response.status === "success") {
          dispatch(hideEditProfile());
        } else {
          console.error(response.message);
        }
      })
      .catch((error) => {
        console.error("Error saving profile data:", error);
      });
  };

  const handleCancel = () => {
    dispatch(hideEditProfile());
  };

  const handleDeleteClick = () => {
    console.log("handleDeleteClick");
    setDeleteVisible(true);
  };

  const handleCloseDelete = () => {
    setDeleteVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  const generateOptions = (start, end, selectedValue = null) => {
    const options = [<option key="empty" value=""></option>]; // Add an empty option
    for (let i = start; i <= end; i++) {
      const value = i.toString().padStart(2, "0"); // Adding leading zero
      options.push(
        <option key={value} value={value}>
          {value}
        </option>
      );
    }
    return options;
  };

  return (
    <div
      id="edit_profile"
      style={{ zIndex: 100, visibility: "visible", left: 0, top: 0 }}
    >
      <div className="top_nav">
        <div className="button_left" onClick={handleCancel}>
          <svg
            className="set_back"
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            style={{ fill: "black", fillRule: "evenodd", clipRule: "evenodd" }}
            viewBox="0 0 4700 8900"
          >
            <path
              className="fil0"
              d="M4589.92 625.66c118.95,-150.59 105.84,-371.54 -35.63,-506.49l-0.01 -0.01c-150.06,-143.14 -389.92,-137.46 -533.06,12.59l-3866.91 4054.06c-78.5,82.32 -112.23,191.64 -101.94,297.2 6.63,83.28 40.65,164.85 102.45,229.68l3865.71 4055.19c143.09,150.1 382.96,155.85 533.06,12.77l0 -0.01c142.17,-135.52 154.81,-357.82 34.19,-508.48l-3393.15 -3559.47c-61.8,-64.83 -95.82,-146.4 -102.45,-229.68 -10.29,-105.56 23.44,-214.88 101.94,-297.2l3395.8 -3560.15z"
            ></path>
          </svg>
        </div>
        <div className="button_left save_edition" onClick={handleSubmit}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlSpace="preserve"
            style={{ fillRule: "evenodd", clipRule: "evenodd" }}
            viewBox="0 0 10001 6602"
          >
            <path
              className="fil0"
              d="M127.56 2915.27c155.93,-155.93 408.43,-132.36 535.58,-0.62l2400.05 2400.05c259.88,259.88 423.44,313.89 534.61,313.28 111.18,-0.61 268.14,-46.81 540.56,-319.22l5216.48 -5216.48c129.79,-129.79 412.46,-116.48 534.18,5.25 121.73,121.72 129.34,398.57 -1.18,529.08l-5757.3 5757.3c-177.58,177.57 -402.34,216.53 -532.74,216.53 -130.4,0 -352.95,-36.76 -529.43,-213.24l-2942.18 -2942.18c-128.65,-124.23 -154.56,-373.83 1.37,-529.75z"
            ></path>
          </svg>
        </div>
      </div>
      <div className="block">
        <div className="inner">
          <label>{t("nickname")}</label>
          <div className="inp">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlSpace="preserve"
              style={{ fillRule: "evenodd", clipRule: "evenodd" }}
              viewBox="0 0 2000 2000"
            >
              <defs>
                <linearGradient id="MyGradient8" gradientTransform="rotate(30)">
                  <stop offset="0%" stopColor="#e70489"></stop>
                  <stop offset="100%" stopColor="#fa5f6f"></stop>
                </linearGradient>
              </defs>
              <path
                className="gradientable8"
                d="M755.33 1192.26c-127.52,11.22 -299.27,75.21 -390.31,165.05 -16.86,16.64 -31.23,23.19 -44.6,38.81 -96.85,113.17 -174.97,235.36 -193.59,443.4 -10.72,119.72 39.31,160.81 154.26,160.45l1441.25 0.03c183.85,0.11 166.58,-142.43 125.13,-303.52 -22.29,-86.59 -62.23,-154.48 -104.22,-217.66 -69.74,-104.95 -209.54,-216.22 -373.68,-263.91 -132.45,-38.48 -465.99,-35.69 -614.24,-22.65zm208.44 -1191.08c-358.47,24.06 -571.82,408.35 -408.01,729.36 82.53,161.73 265.5,280.81 478.96,266.9 259.28,-16.9 482.22,-244.94 462.11,-534.23 -18.25,-262.44 -246.04,-481.29 -533.06,-462.03z"
              ></path>
            </svg>

            <input
              type="text"
              id="nickname"
              value={formData.nickname}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="inner">
          <label>{t("email")}</label>
          <div className="inp">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <defs>
                <linearGradient id="MyGradient8" gradientTransform="rotate(30)">
                  <stop offset="0%" stopColor="#e70489"></stop>
                  <stop offset="100%" stopColor="#fa5f6f"></stop>
                </linearGradient>
              </defs>
              <path
                className="gradientable8"
                d="M256 64C150 64 64 150 64 256s86 192 192 192c17.7 0 32 14.3 32 32s-14.3 32-32 32C114.6 512 0 397.4 0 256S114.6 0 256 0S512 114.6 512 256v32c0 53-43 96-96 96c-29.3 0-55.6-13.2-73.2-33.9C320 371.1 289.5 384 256 384c-70.7 0-128-57.3-128-128s57.3-128 128-128c27.9 0 53.7 8.9 74.7 24.1c5.7-5 13.1-8.1 21.3-8.1c17.7 0 32 14.3 32 32v80 32c0 17.7 14.3 32 32 32s32-14.3 32-32V256c0-106-86-192-192-192zm64 192a64 64 0 1 0 -128 0 64 64 0 1 0 128 0z"
              ></path>
            </svg>

            <input
              type="email"
              id="email"
              value={formData.email}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="inner">
          <label>{t("pass")}</label>
          <div className="inp">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <defs>
                <linearGradient id="MyGradient8" gradientTransform="rotate(30)">
                  <stop offset="0%" stopColor="#e70489"></stop>
                  <stop offset="100%" stopColor="#fa5f6f"></stop>
                </linearGradient>
              </defs>
              <path
                className="gradientable8"
                d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z"
              ></path>
            </svg>

            <input
              type="password"
              id="password"
              value={formData.password}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="inner">
          <label>{t("interface_lang")}</label>
          <div className="inp">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <defs>
                <linearGradient id="MyGradient8" gradientTransform="rotate(30)">
                  <stop offset="0%" stopColor="#e70489"></stop>
                  <stop offset="100%" stopColor="#fa5f6f"></stop>
                </linearGradient>
              </defs>
              <path
                className="gradientable8"
                d="M57.7 193l9.4 16.4c8.3 14.5 21.9 25.2 38 29.8L163 255.7c17.2 4.9 29 20.6 29 38.5v39.9c0 11 6.2 21 16 25.9s16 14.9 16 25.9v39c0 15.6 14.9 26.9 29.9 22.6c16.1-4.6 28.6-17.5 32.7-33.8l2.8-11.2c4.2-16.9 15.2-31.4 30.3-40l8.1-4.6c15-8.5 24.2-24.5 24.2-41.7v-8.3c0-12.7-5.1-24.9-14.1-33.9l-3.9-3.9c-9-9-21.2-14.1-33.9-14.1H257c-11.1 0-22.1-2.9-31.8-8.4l-34.5-19.7c-4.3-2.5-7.6-6.5-9.2-11.2c-3.2-9.6 1.1-20 10.2-24.5l5.9-3c6.6-3.3 14.3-3.9 21.3-1.5l23.2 7.7c8.2 2.7 17.2-.4 21.9-7.5c4.7-7 4.2-16.3-1.2-22.8l-13.6-16.3c-10-12-9.9-29.5 .3-41.3l15.7-18.3c8.8-10.3 10.2-25 3.5-36.7l-2.4-4.2c-3.5-.2-6.9-.3-10.4-.3C163.1 48 84.4 108.9 57.7 193zM464 256c0-36.8-9.6-71.4-26.4-101.5L412 164.8c-15.7 6.3-23.8 23.8-18.5 39.8l16.9 50.7c3.5 10.4 12 18.3 22.6 20.9l29.1 7.3c1.2-9 1.8-18.2 1.8-27.5zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z"
              ></path>
            </svg>

            <select id="lang" value={formData.lang} onChange={handleChange}>
              {lang.map((l) => (
                <option key={l} value={l}>
                  {langNames[l]}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="inner">
          <label>{t("gender")}</label>
          <div className="inp">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
              <defs>
                <linearGradient id="MyGradient8" gradientTransform="rotate(30)">
                  <stop offset="0%" stopColor="#e70489"></stop>
                  <stop offset="100%" stopColor="#fa5f6f"></stop>
                </linearGradient>
              </defs>
              <path
                className="gradientable8"
                d="M176 288a112 112 0 1 0 0-224 112 112 0 1 0 0 224zM352 176c0 86.3-62.1 158.1-144 173.1V384h32c17.7 0 32 14.3 32 32s-14.3 32-32 32H208v32c0 17.7-14.3 32-32 32s-32-14.3-32-32V448H112c-17.7 0-32-14.3-32-32s14.3-32 32-32h32V349.1C62.1 334.1 0 262.3 0 176C0 78.8 78.8 0 176 0s176 78.8 176 176zM271.9 360.6c19.3-10.1 36.9-23.1 52.1-38.4c20 18.5 46.7 29.8 76.1 29.8c61.9 0 112-50.1 112-112s-50.1-112-112-112c-7.2 0-14.3 .7-21.1 2c-4.9-21.5-13-41.7-24-60.2C369.3 66 384.4 64 400 64c37 0 71.4 11.4 99.8 31l20.6-20.6L487 41c-6.9-6.9-8.9-17.2-5.2-26.2S494.3 0 504 0H616c13.3 0 24 10.7 24 24V136c0 9.7-5.8 18.5-14.8 22.2s-19.3 1.7-26.2-5.2l-33.4-33.4L545 140.2c19.5 28.4 31 62.7 31 99.8c0 97.2-78.8 176-176 176c-50.5 0-96-21.3-128.1-55.4z"
              ></path>
            </svg>

            <select id="gender" value={formData.gender} onChange={handleChange}>
              <option value="m">{t("man")}</option>
              <option value="f">{t("woman")}</option>
              <option value="na">{t("it")}</option>
            </select>
          </div>
        </div>
        <div className="inner">
          <label>{t("birthday")}</label>
          <div className="inp edit_age">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
              <defs>
                <linearGradient id="MyGradient8" gradientTransform="rotate(30)">
                  <stop offset="0%" stopColor="#e70489"></stop>
                  <stop offset="100%" stopColor="#fa5f6f"></stop>
                </linearGradient>
              </defs>
              <path
                className="gradientable8"
                d="M32 0C14.3 0 0 14.3 0 32S14.3 64 32 64V75c0 42.4 16.9 83.1 46.9 113.1L146.7 256 78.9 323.9C48.9 353.9 32 394.6 32 437v11c-17.7 0-32 14.3-32 32s14.3 32 32 32H64 320h32c17.7 0 32-14.3 32-32s-14.3-32-32-32V437c0-42.4-16.9-83.1-46.9-113.1L237.3 256l67.9-67.9c30-30 46.9-70.7 46.9-113.1V64c17.7 0 32-14.3 32-32s-14.3-32-32-32H320 64 32zM96 75V64H288V75c0 19-5.6 37.4-16 53H112c-10.3-15.6-16-34-16-53zm16 309c3.5-5.3 7.6-10.3 12.1-14.9L192 301.3l67.9 67.9c4.6 4.6 8.6 9.6 12.2 14.9H112z"
              ></path>
            </svg>

            <select
              value={formData.age[2]} // день
              onChange={(e) => handleAgeChange(e, 2)}
            >
              {generateOptions(1, 31)}
            </select>
            <select
              value={formData.age[1]} // місяць
              onChange={(e) => handleAgeChange(e, 1)}
            >
              {generateOptions(1, 12)}
            </select>
            <select
              value={formData.age[0]} // рік
              onChange={(e) => handleAgeChange(e, 0)}
            >
              {generateOptions(1900, new Date().getFullYear())}
            </select>
          </div>
        </div>
        <div className="inner">
          <div className="inp">
            <div
              className="grey_button del_acc"
              style={{
                background: "#e0e5f1",
                borderRadius: "5pt",
                padding: "5pt 15pt",
                fontWeight: 400,
                textAlign: "center",
              }}
              onClick={handleDeleteClick}
            >
              {t("system.del_acc")}
            </div>
          </div>
        </div>
      </div>
      <LightboxDeleteAccount
        isVisible={isDeleteVisible}
        onClose={handleCloseDelete}
      />
    </div>
  );
};

export default EditProfile;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { apiRequest } from "utils/api";
import {
  toggleUserProfile,
  updatePublisherId,
  updateSlidePage,
} from "features/userProfile/userProfileSlice";
import { toggleZoom } from "utils/toggleZoom"; // імпорт функції toggleZoom

function UserProfile() {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.user.data); // Отримання user_id з Redux
  const [userProfile, setUserProfile] = useState(null); // Стейт для зберігання даних профілю користувача
  const [error, setError] = useState(""); // Стейт для зберігання помилки
  const { t } = useTranslation();

  useEffect(() => {
    if (userId) {
      // Якщо userId є, виконуємо запит до API
      apiRequest("get_user_profile.php", "POST", { user_id: userId })
        .then((response) => {
          if (response.status === "success") {
            setUserProfile(response.data); // Зберігаємо дані користувача у стейті
          } else {
            setError(
              response.message || "Не вдалося завантажити профіль користувача."
            );
          }
        })
        .catch((error) => {
          console.error("Error fetching user profile:", error);
          setError("Не вдалося завантажити профіль користувача.");
        });
    }
  }, [userId]); // Виконується, коли змінюється userId

  useEffect(() => {
    if (userProfile) {
      // Вимкнути масштабування при завантаженні профілю
      toggleZoom("#load_user", false);
    }
  }, [userProfile]);

  const handleClose = () => {
    // Вимкнути масштабування при закритті профілю
    toggleZoom("#load_user", false); 
    dispatch(toggleUserProfile());
  };

  // Функція для підписки/відписки користувача
  const toggleSubscription = async () => {
    try {
      const response = await apiRequest("subscribe_user.php", "POST", {
        publisher_id: userId,
        subscribe: userProfile.isSubscribed ? "0" : "1",
      });

      if (response.status === "success") {
        // Оновлення даних користувача та кількості підписників
        setUserProfile((prevProfile) => ({
          ...prevProfile,
          isSubscribed: !prevProfile.isSubscribed,
          subscribers: new Array(parseInt(response.subscribers_count)).fill(
            null
          ), // Оновлення кількості підписників
        }));
      } else {
        setError("Не вдалося оновити підписку.");
      }
    } catch (error) {
      console.error("Error toggling subscription:", error);
      setError("Не вдалося оновити підписку.");
    }
  };

  return (
    <div
      id="load_user"
      style={{ zIndex: 100, top: 0, opacity: 1, visibility: "visible" }}
    >
      <div className="top_nav">
        <div className="button_left">
          <svg
            className="set_back"
            onClick={handleClose}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 4700 8900"
            style={{ fill: "black", fillRule: "evenodd", clipRule: "evenodd" }}
          >
            <path
              className="fil0"
              d="M4589.92 625.66c118.95,-150.59 105.84,-371.54 -35.63,-506.49l-0.01 -0.01c-150.06,-143.14 -389.92,-137.46 -533.06,12.59l-3866.91 4054.06c-78.5,82.32 -112.23,191.64 -101.94,297.2 6.63,83.28 40.65,164.85 102.45,229.68l3865.71 4055.19c143.09,150.1 382.96,155.85 533.06,12.77l0 -0.01c142.17,-135.52 154.81,-357.82 34.19,-508.48l-3393.15 -3559.47c-61.8,-64.83 -95.82,-146.4 -102.45,-229.68 -10.29,-105.56 23.44,-214.88 101.94,-297.2l3395.8 -3560.15z"
            />
          </svg>
        </div>
      </div>
      {error && <div className="error">{error}</div>}
      {userProfile ? (
        <div className="cont">
          <div className="block profile">
            <div className="avatar">
              <img
                src={userProfile.photo || process.env.REACT_APP_IMG_DOMAIN + "/img/icons/default_avatar.png"}
                alt="User Avatar"
              />
            </div>
            <div className="nickname">
              {userProfile.nickname}
              {userProfile.isSubscribed ? (
                <div
                  className="grey_button subscribe subscribed"
                  data-userid={userId}
                  onClick={toggleSubscription}
                >
                  {t("system.unsubscribe")}
                </div>
              ) : (
                <div
                  className="grey_button subscribe"
                  data-userid={userId}
                  onClick={toggleSubscription}
                >
                  {t("system.subscribe")}
                </div>
              )}
            </div>
            <div className="buttons">
              <div className="profile-box">
                <span>{userProfile.posts ? Object.keys(userProfile.posts).length : 0}</span>
                <span> {t("posts")}</span>
              </div>
              <div className="profile-box">
                <span id="subscribers_count">
                  {userProfile.subscribers ? userProfile.subscribers.length : 0}
                </span>
                <span> {t("followers")}</span>
              </div>
              <div className="profile-box">
                <span>{userProfile.favoritesCount || 0}</span>
                <span>{t("in_favorites")}</span>
              </div>
            </div>
          </div>
          <div className="grid_container">
            <div className="slide">
              <div></div>
            </div>
            <div className="grid" id="my_media">
              {userProfile.posts &&
                Object.values(userProfile.posts).map((post, index) => (
                  <div
                    key={post.id}
                    className="thumb user_post"
                    data-postid={post.id}
                    data-userid={userId}
                    onClick={() => {
                      dispatch(updatePublisherId(userId));
                      dispatch(updateSlidePage(index + 1));
                      handleClose();
                    }}
                  >
                    <img
                      src={process.env.REACT_APP_IMG_DOMAIN + "/" + post.pic}
                      alt="User Post"
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
      ) : (
        <div>{t("system.loading")}...</div>
      )}
    </div>
  );
}

export default UserProfile;




import React from "react";
import { useTranslation } from "react-i18next";

const UnsubscribeLightbox = ({
  isVisible,
  onClose,
  onConfirm,
  publisherId,
}) => {
  if (!isVisible) return null;
  const { t } = useTranslation();

  return (
    <div
      className="lightbox unsubscribe_box"
      style={{ visibility: "visible", opacity: 1 }}
    >
      <div className="box">
        <div className="header">
          <span>{t("system.unsubscribe_user")}</span>
          <div className="close_unsubscribe_box" onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlSpace="preserve"
              style={{ fillRule: "evenodd", clipRule: "evenodd" }}
              viewBox="0 0 501 501"
            >
              <path
                className="fil0"
                d="M37.29 6.53l212.97 212.96 212.97 -212.98c8.44,-8.43 22.24,-8.43 30.67,0l0.02 0.03c8.45,8.43 8.45,22.24 0,30.67l-212.96 212.97 212.96 212.97c8.44,8.44 8.44,22.24 0,30.67l-0.02 0.02c-8.43,8.45 -22.24,8.45 -30.67,0l-212.97 -212.96 -212.96 212.95c-8.44,8.45 -22.24,8.45 -30.68,0l-0.01 -0.01c-8.45,-8.44 -8.45,-22.24 0,-30.67l212.95 -212.97 -212.95 -212.96c-8.45,-8.44 -8.45,-22.24 0,-30.68l0.01 -0.01c8.44,-8.45 22.24,-8.45 30.67,0z"
              />
            </svg>
          </div>
        </div>
        <div className="content">
          <span>{t("system.unsubscribe_user_conf")}</span>
          <div>
            <input
              type="hidden"
              id="unsubscribe_publisher_id"
              value={publisherId}
            />
            <div className="button unsubscribe_yes" onClick={onConfirm}>
              Так
            </div>
            <div className="button" onClick={onClose}>
              {t("system.cancel")}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnsubscribeLightbox;

import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";

const SubComments = memo(({ parentId, comments, renderComment }) => {
  const { t } = useTranslation();

  const subComments = useMemo(
    () => comments.filter((comment) => comment.parent === parentId),
    [comments, parentId]
  );

  if (subComments.length === 0) return null;

  return (
    <div className="tab accordion">
      <input
        type="checkbox"
        className="checker"
        id={`chck1_${parentId}`}
        name="bra"
      />
      <label
        className="tab-label"
        htmlFor={`chck1_${parentId}`}
        data-count={subComments.length}
      >
        {t("system.view_replies")} ({subComments.length})
      </label>
      <div className="w100 answers">
        {subComments.map((comment) => (
          <React.Fragment key={comment.id}>
            {renderComment(comment, 2)} {/* Передаємо рівень 2 */}
          </React.Fragment>
        ))}
      </div>
    </div>
  );
});

export default SubComments;
